import React from "react";
import MainBlog from "../components/MainBlog";
import Header from "../../Header/components/Header";
import Footer from "../../Footer/components/Footer";
import "../styles/BlogFormat.css";
import FooterBlog from "../components/FooterBlog";
import { Helmet } from "react-helmet";

function ImpuestoElectrico() {
    const title = "Descubre el Misterio del Impuesto sobre la Electricidad: Medidas Relevantes para el 2024 que Impactan tu Factura de Luz";
    const description = "Entiende las implicaciones del Impuesto sobre la Electricidad en tu factura de la luz. Analizamos las medidas clave para el 2024 y cómo afectan tus gastos energéticos. Descubre características, cálculos, y estrategias para comprender y reducir este impuesto en LuzPorHoras.es.";
    const keywords = "Impuesto sobre la Electricidad, factura de luz, tarifa PVPC, IVA de la luz, IVA del gas, Impuesto Especial de Electricidad, bono social, TUR 4, medidas 2024, consumo responsable, cálculo del impuesto, LuzPorHoras.es";
    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta name="author" content="Saúl Del Rosario Sosa" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content="https://s1.eestatic.com/2022/03/17/sociedad/consumo/657944297_222759406_1706x960.jpg" />
                <meta property="og:url" content="https://luzporhoras.es" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content="https://s1.eestatic.com/2022/03/17/sociedad/consumo/657944297_222759406_1706x960.jpg" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Header />
            <div className="main_container">
                <div className="main_content">
                    <div className="header_content">
                        <h1 className="header_title">¿Entiendes tu Factura de la Luz? Descubre el Misterio del Impuesto sobre la Electricidad</h1>
                        <MainBlog time={2} />
                    </div>
                    <div className="line_space" />
                    <div className="blog_content">

                        <p>El Impuesto sobre la Electricidad es un componente presente en la factura de la luz que a menudo resulta desconocido. Aquí te explicamos algunas medidas relevantes para el 2024 que afectan a la factura de luz y gas:</p>

                        <ol>
                            <li>Finaliza la aplicación del tope del gas para consumidores de la tarifa PVPC y usuarios peninsulares de mercado libre.</li>
                            <li>Sube el IVA de la luz al 10% durante todo el 2024 si se tiene contratado menos de 10 kW de potencia.</li>
                            <li>Sube el IVA del gas al 10% durante los primeros tres meses de 2024; a partir de abril, este impuesto ascenderá al 21%.</li>
                            <li>El Impuesto Especial de Electricidad (IEE) aumenta al 2.5% en los primeros tres meses de 2024 y al 3.8% hasta el 30 de junio de 2024.</li>
                            <li>Se prorrogan los descuentos temporales del bono social hasta el 30 de junio de 2024.</li>
                            <li>La TUR 4 se prorroga hasta el 30 de junio de 2024, excluyendo a quienes no hayan instalado repartidores de costes o contadores individuales de calefacción.</li>
                        </ol>

                        <h2>¿Qué es el Impuesto sobre la Electricidad?</h2>
                        <p>El Impuesto sobre la Electricidad es un impuesto especial de fabricación establecido por el Ministerio de Industria, similar a los impuestos sobre la gasolina o el tabaco. Este impuesto representa un 5.113% del término de potencia y de consumo en todos los recibos de la luz, independientemente de la tarifa o compañía eléctrica.</p>

                        <div>Aunque inicialmente se creó para apoyar al sector minero, los fondos recaudados se destinan a áreas públicas como la sanidad y la educación.</div>

                        <h3>Características del Impuesto sobre la Electricidad</h3>
                        <ul>
                            <li>Recauda el antiguo "coste específico asignado a la minería del carbón".</li>
                            <li>A pesar de ser un impuesto de fabricación, también afecta al término de consumo.</li>
                            <li>El 21% de IVA se aplica sobre el total de la factura, incluido este impuesto.</li>
                            <li>Representa una pequeña parte de la factura eléctrica, solo un 5.113% del importe del término de consumo y potencia.</li>
                        </ul>

                        <p>Este impuesto compensa la eliminación del recargo sobre la facturación eléctrica que antes financiaba el sector minero, siendo recaudado por las comercializadoras en nombre del Gobierno.</p>

                        <h3>¿Por qué debo pagarlo?</h3>
                        <p>El Impuesto sobre la Electricidad debe pagarlo cualquier persona que tenga contratado un suministro eléctrico, incluso si no está consumiendo energía en ese momento. Se aplica para garantizar el consumo responsable de los recursos naturales y promover condiciones medioambientales saludables.</p>

                        <h3>¿Cómo se calcula en mi factura?</h3>
                        <p>Este impuesto se aplica sobre el total del importe del término de potencia y del término de consumo eléctrico. A continuación, te mostramos un ejemplo de cálculo:</p>
                        <div>
                            <p>Supongamos que el consumo eléctrico de un cliente en los últimos dos meses fue de 500 kWh, con una potencia contratada de 5.75 kW y una tarifa de 0.12230€/kWh.</p>

                            <ul>
                                <li>Término de Consumo: 500 kWh x 0.12230€/kWh = 61.15€</li>
                                <li>Potencia Contratada: 5.75 kW x 2 meses x 3.50€/mes = 40.25€</li>
                                <li>Término de Consumo + Potencia Contratada: 101.4€</li>
                                <li>Impuesto sobre la Electricidad: 5.113% x (Potencia + Consumo) = 0.05113 x 101.4 = 5.184€</li>
                            </ul>
                        </div>

                    </div>
                    <FooterBlog />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ImpuestoElectrico;
