import React from "react";
import Header from "../../Header/components/Header";
import PrecioLuzManana from "./PrecioLuzManana";
import Footer from "../../Footer/components/Footer";
import { Helmet } from "react-helmet";



function Manana(){

    const title = "Consulta los Precios de la Luz Mañana | Detalles y Evolución de la Tarifa PVPC | LuzPorHoras.es";
    const description = "Descubre la evolución de la tarifa PVPC y consulta los detalles de los precios de la luz para mañana en España con LuzPorHoras.es. Encuentra información sobre el PVPC, cómo se calcula y consejos para ahorrar en tu factura eléctrica.";
    const keywords = "precios de la luz mañana, tarifa PVPC, evolución de precios, Red Eléctrica de España, subasta de precios diaria, proceso de subasta, actualización de precios, hora señalada, falta de disponibilidad, consejos de eficiencia energética, Precio Voluntario para el Pequeño Consumidor, PVPC, cómo se calcula el PVPC, ahorro en la factura de la luz, datos verificados, transparencia en tarifas eléctricas, LuzPorHoras.es.";
    return(
        <div>
            <Helmet>
            <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta name="author" content="Saúl Del Rosario Sosa" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content="https://s1.eestatic.com/2022/03/17/sociedad/consumo/657944297_222759406_1706x960.jpg" />
                <meta property="og:url" content="https://luzporhoras.es" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content="https://s1.eestatic.com/2022/03/17/sociedad/consumo/657944297_222759406_1706x960.jpg" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Header/>
            <PrecioLuzManana/>
            <Footer/>
        </div>
    )
}

export default Manana;