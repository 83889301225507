import React, { useEffect, useState } from "react";
import "../styles/PopUp.css";
import cross from "../img/cross.svg";

function PopUp() {

    const [showPopUp, setShowPopUp] = useState(false);

    const closePopUp = () => {
        const popup = document.getElementsByClassName("popup")[0];
        popup.style.display = "none";
    }

    const openMain = () => {
        window.location.href = "/";
    }

    useEffect(() => {
        if (window.location.pathname !== "/") {
            if (window.location.pathname !== "/luz-manana") {
                if (!sessionStorage.getItem('popupShown')) {
                    setShowPopUp(true);
                    sessionStorage.setItem('popupShown', 'true');
                }
            }
        }
    }, []);

    return (
        <div>
            {showPopUp ? <div className="popup">
                <div className="popup_inner">
                    <h2>Consulta Ya los Precios Por Hora de Hoy</h2>
                    <button onClick={openMain}>¡Accede Ahora!</button>
                    <img src={cross} alt="Cerrar PopUp" onClick={closePopUp} />
                </div>
            </div> : null}
        </div>
    );
}

export default PopUp;