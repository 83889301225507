import React, { useEffect, useState } from "react";
import "../styles/PrecioLuzActual.css";
import CircularProgressBar from "./CircularProgressBar";
import axios from 'axios';
import ListPrice from "./ListPrice";
import TresPrecios from "./TresPrecios";
import BestRange from "./BestRange";
import Plot from "./Plot";
import Explains from "./Explains";
import calend from "../../Blog/img/calendar.svg";
import AnuncioApp from "../../anuncioApp/components/AnuncioApp";
import FooterBlog from "../../Blog/components/FooterBlog";
import ExplainAhorro from "./ExplainAhorro";

function PrecioLuzActual() {
    const [radius, setRadius] = useState(0);
    const [findData, setFindData] = useState(false);
    const [progress, setProgress] = useState(0);
    const [priceData, setPriceData] = useState([]);
    const [orderedPriceData, setOrderedPriceData] = useState([]);
    const [priceActual, setPriceActual] = useState(0);
    const [num_hora_ratio, setNum_hora_ratio] = useState();
    const [color, setColor] = useState("rgb(255, 255, 255)");

    // box-shadow: 4px 8px 24px var(--box-shadow-color);

    const getToday = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const getTodayGood = () => {
        const today = new Date();
        today.setDate(today.getDate()); // Add one day to today
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');

        return `${day}-${month}-${year}`;
    }

    const getActualHourAndMinutes = () => {
        const today = new Date();
        const hour = today.getHours().toString().padStart(2, '0');
        const minutes = today.getMinutes().toString().padStart(2, '0');

        return `${hour}:${minutes}`;
    }

    // Simulate upload progress
    useEffect(() => {
        setRadius(window.innerWidth > 500 ? 160 : 100);

        const fetchData = async () => {
            try {
                // Replace 'YOUR_API_KEY' with the actual API key if required
                // const apiKey = 'YOUR_API_KEY';
                const apiUrl = `https://apidatos.ree.es/en/datos/mercados/precios-mercados-tiempo-real?start_date=${getToday()}T00:00&end_date=${getToday()}T23:59&time_trunc=hour&geo_ids=8741`;

                const response = await axios.get(apiUrl, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                });
                response.data.included[0].attributes.values.forEach((data, index) => {
                    const hour = Number(index);
                    const price = data.value;
                    const percentage = 1;
                    setPriceData((prevData) => {
                        if (prevData.length < 24) {
                            return [...prevData, { price, hour, percentage }]
                        } else {
                            return prevData;
                        }
                    });
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

    }, []);

    useEffect(() => {
        if (priceData.length === 24) {
            setOrderedPriceData(() => {
                const sortedData = [...priceData].sort((a, b) => a.price - b.price);
                let lastData = 1;
                sortedData.forEach((data, index) => {
                    data.percentage = lastData * (100 / 24);
                    lastData = lastData + 1;
                });

                const sortedDataHour = [...priceData].sort((a, b) => a.hour - b.hour);
                return sortedDataHour;
            });
        }
    }, [priceData]);

    useEffect(() => {

        if (orderedPriceData.length > 0) {
            const date = new Date();
            const currentHour = date.getHours();

            // const currentHour = 13;
            setPriceActual(orderedPriceData[currentHour].price);
            setProgress(orderedPriceData[currentHour].percentage);

            const tempo = orderedPriceData[currentHour].percentage / 100 * 24;
            const calc_worst = Number(Math.abs(tempo - 25)).toFixed(0)
            const calc_best = Number(Math.abs(tempo)).toFixed(0)
            if (tempo > 12) {
                if (calc_worst === "1") {
                    setNum_hora_ratio("Precio a las "+ getActualHourAndMinutes() + "h - " + "Esta es la hora mas cara del día");
                } else {
                    setNum_hora_ratio("Precio a las "+getActualHourAndMinutes() + "h - " +"Esta es la " + calc_worst + "ª hora más cara del día");
                }

            } else {
                if (calc_best === "1") {
                    setNum_hora_ratio("Precio a las "+getActualHourAndMinutes() + "h - " + "Esta es la hora mas barata del día");
                } else {
                    setNum_hora_ratio("Precio a las "+getActualHourAndMinutes() + "h - " + "Esta es la " + calc_best + "ª hora más barata del día");
                }

            }
        }
    }, [orderedPriceData]);

    useEffect(() => {
        if (progress > 0) {
            setFindData(true);
        }
    }, [progress]);

    return (
        <div className="precio_luz_actual_full">
            <div className="precio_luz_actual">
                <div className="day_text">
                    <h1 className="day_text_h1">Consulta el precio de la luz hoy: Detalles y Evolución de la tarifa PVPC</h1>
                    <div className="date_container">
                        <img className="header_date_icon" src={calend} alt="Icono Calendario" />
                        <p className="header_date_main">Actualizado a {getTodayGood()} {"->"} {getActualHourAndMinutes()}h</p>
                    </div>
                </div>
                <div className="progress_precio_luz">
                    <div className="circulo">
                        {findData ? <CircularProgressBar radius={radius} progressPercentage={progress} priceActual={priceActual} color={color} setColor={setColor} /> : null}
                    </div>
                </div>
                <div style={{ boxShadow: `0px 0px 24px ${color}` }} className="precio_luz_actual_text">
                    <p>{num_hora_ratio}</p>
                </div>
                {progress ? <TresPrecios priceData={orderedPriceData} /> : null}
                <AnuncioApp />
                {progress ? <BestRange priceData={orderedPriceData} /> : null}
                {progress ? <ListPrice priceData={orderedPriceData} setDay={0} /> : null}
                <ExplainAhorro />
                {progress ? <Plot priceData={orderedPriceData} /> : null}
                <FooterBlog />
                <Explains />
                <AnuncioApp />
            </div>
        </div>
    );
}

export default PrecioLuzActual;