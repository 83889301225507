import React from "react";
import "../styles/MainBlog.css";
import calendar from "../img/calendar.svg";
import clock from "../img/clock.svg";
import AnuncioApp from "../../anuncioApp/components/AnuncioApp";

function MainBlog({ time }) {

    const getTodayGood = () => {
        const today = new Date();
        const randomNumber = Math.floor(Math.random() * today.getDate()) + 1;
        today.setDate(randomNumber);
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');

        return `${day}/${month}/${year}`;
    }

    return (
        <div>
            <div className="header_subtitle">
                <div className="date_container">
                    <img className="header_date_icon" src={calendar} alt="Icono Calendario" />
                    <p className="header_date">Actualizado a {getTodayGood()}</p>
                </div>
                <p className="header_author_sub">Escrito por</p>
                <p className="header_author">Saúl Del Rosario Sosa</p>
                <p className="header_author_sub">Redactor jefe de LuzPorHoras.es</p>
                <div className="time_container">
                    <p className="header_time">Tiempo de lectura: {time} minutos </p>
                    <img className="header_date_icon" src={clock} alt="Icono Reloj" />
                </div>
            </div>
            <AnuncioApp />
        </div>

    );
}

export default MainBlog;