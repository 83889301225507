import React, { useState, useEffect } from "react";
import "../styles/FooterBlog.css";
import arrow from "../img/arrow_footer_blog_button.svg";
import AnuncioApp from "../../anuncioApp/components/AnuncioApp";
import totalArticles from "../../Entries";

function FooterBlog() {

    const [article, setArticle] = useState([]);

    useEffect(() => {
        setArticle(randomRelatedArticles());
    }, []);


    const randomRelatedArticles = () => {
        const randomArticle = totalArticles.sort(() => Math.random() - 0.5).slice(0, 4);
        return randomArticle;
    };

    const handleClick = (e) => {
        window.location.href = article[e.target.id].link;
    };

    return (
        <div className="footer_blog_container">

            <h1 className="footer_blog_title">Artículos Relacionados</h1>


            {article.length > 3 ?
                <div className="footer_blog_button_container">
                    <button className="footer_button" id="0" onClick={handleClick}>{article[0].nombre}<img src={arrow} alt="Flecha Link"></img></button>
                    <button className="footer_button" id="1" onClick={handleClick}>{article[1].nombre}<img src={arrow} alt="Flecha Link"></img></button>
                    <button className="footer_button" id="2" onClick={handleClick}>{article[2].nombre}<img src={arrow} alt="Flecha Link"></img></button>
                    <button className="footer_button" id="3" onClick={handleClick}>{article[3].nombre}<img src={arrow} alt="Flecha Link"></img></button>
                </div> : null}

            {/* <div className="footer_blog_button_container">

                <button className="footer_button">FUERZA DE LA GRAVEDAD<img src={arrow} alt="Flecha Link"></img></button>
                <button className="footer_button">teoria de la luz newtoniana<img src={arrow} alt="Flecha Link"></img></button>
                <button className="footer_button">asteroides y planetas<img src={arrow} alt="Flecha Link"></img></button>
                <button className="footer_button">los teoremas de las matematicas<img src={arrow} alt="Flecha Link"></img></button>
            </div> */}
        </div>
    );
}

export default FooterBlog;