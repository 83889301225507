import React from "react";
import "../../main/styles/Explains.css";


function WaitingPrices() {
    return (
        <div className="explains_container">
            <div className="explains">
                <h1>Los Precios de Mañana no están actualmente disponibles</h1>
                <p>En este momento, los precios de la luz para el día siguiente aún no están disponibles en nuestra plataforma. Esto se debe a que la Red Eléctrica de España realiza la subasta de precios diaria, y la información correspondiente estará disponible después de las 8:15 pm.</p>
                <p>La subasta es un proceso clave en el que se determinan los costos de la energía eléctrica para el próximo día, y nosotros nos esforzamos por proporcionar esta información de manera inmediata después de recibirla.</p>
                <div className="a_explains">
                    <a href="/">Si lo deseas, puedes consultar los precios de hoy.</a>
                </div>
            </div>
            <div className="explains">
                <h1>¿Por qué no se encuentran disponibles los precios de la luz para mañana?</h1>
                <p>La falta de disponibilidad de los precios de la luz para mañana en este momento se debe al proceso de subasta llevado a cabo por la Red Eléctrica de España. Esta subasta, que determina los costos de la energía eléctrica para el próximo día, concluye alrededor de las 8:15 pm.</p>
                <p>Una vez que recibimos esta información crucial, nos esforzamos por actualizar nuestra plataforma de manera inmediata para proporcionarte los datos más recientes y precisos. Este enfoque nos permite ofrecer a nuestros usuarios información relevante para tomar decisiones informadas sobre el consumo de energía.</p>
                <p>Si tienes más preguntas o necesitas asistencia, no dudes en ponerte en contacto con nosotros.</p>
            </div>

            <div className="explains">
                <h1>¿Qué hago si es la hora y aún no se muestran los precios de mañana?</h1>
                <p>Entendemos que la actualización de los precios es crucial para tu toma de decisiones. Si es la hora señalada y aún no se muestran los precios de mañana, te recomendamos seguir estos pasos:</p>
                <ol>
                    <li><strong>Verifica la hora:</strong> Asegúrate de que sea después de las 8:15 pm, momento en el cual la Red Eléctrica de España finaliza la subasta y actualiza los precios.</li>
                    <li><strong>Recarga la página:</strong> Puede haber un pequeño retraso en la actualización. Intenta recargar la página para asegurarte de obtener la información más reciente.</li>
                    <li><strong>Espera unos minutos:</strong> La carga de datos puede llevar algún tiempo. Te sugerimos esperar unos minutos y volver a verificar.</li>
                    <li><strong>Contacta con nosotros:</strong> Si después de seguir estos pasos los precios aún no se muestran, no dudes en ponerte en contacto con nosotros. Estamos aquí para ayudarte y asegurarnos de que recibas la información que necesitas.</li>
                </ol>
                <p>Agradecemos tu comprensión y colaboración. Nuestro objetivo es proporcionarte la información más actualizada de manera eficiente. Siempre trabajamos para mejorar tu experiencia con nuestra plataforma.</p>
            </div>

        </div>

    )
}

export default WaitingPrices;