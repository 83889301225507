import React from "react";
import Header from "../../Header/components/Header";
import Footer from "../../Footer/components/Footer";
import "../styles/AboutUs.css";
import { Helmet } from "react-helmet";

function AboutUs() {

    const title = "Acerca de LuzPorHoras.es - Iluminamos Tu Ahorro con Energía Sostenible";
    const description = "Descubre la comunidad comprometida de LuzPorHoras.es, dedicada a facilitar el ahorro de energía de manera inteligente y sostenible. Con valores fundamentales de innovación sostenible, empoderamiento del usuario y compromiso con la comunidad, te ofrecemos una experiencia transformadora. Únete a nuestra revolución energética hoy.";
    const keywords = "LuzPorHoras.es, precio de la luz, ahorro de energía, comunidad sostenible, innovación sostenible, empoderamiento del usuario, compromiso con la comunidad, ahorro significativo, educación continua, transparencia absoluta, experiencia transformadora, revolución energética, energía sostenible, blog educativo, formas de ahorrar en la factura de la luz, mejores tarifas de luz, Luz por Horas Hoy, Luz por Horas Mañana, LuzPorHoras.es 2024";

    return (

        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta name="author" content="Saúl Del Rosario Sosa" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content="https://s1.eestatic.com/2022/03/17/sociedad/consumo/657944297_222759406_1706x960.jpg" />
                <meta property="og:url" content="https://luzporhoras.es" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content="https://s1.eestatic.com/2022/03/17/sociedad/consumo/657944297_222759406_1706x960.jpg" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Header />
            <div className="about_us_content">
                <p>Bienvenido a <a href="/">luzporhoras.es</a> - Iluminamos Tu Ahorro!</p>

                <h2>Nuestra Identidad</h2>
                <p>En <a href="/">luzporhoras.es</a>, no somos solo una plataforma de información sobre el precio de la luz. Somos una
                    comunidad comprometida con un propósito: facilitar el ahorro de energía de manera inteligente y sostenible. A
                    través de nuestra dedicación y transparencia, hemos creado una identidad de marca que trasciende lo común.</p>

                <h2>Nuestra Luz, Tus Horas</h2>
                <h3>Valores Fundamentales</h3>
                <ol>
                    <li><strong>Innovación Sostenible:</strong> Nos esforzamos por ser líderes en la vanguardia de la tecnología
                        sostenible.</li>
                    <li><strong>Empoderamiento del Usuario:</strong> Creemos en dar a nuestros usuarios el control total de su
                        consumo de energía.</li>
                    <li><strong>Compromiso con la Comunidad:</strong> Somos más que una plataforma; somos una comunidad que se
                        apoya mutuamente en el camino hacia un futuro más sostenible.</li>
                </ol>

                <h3>Compromisos Claros</h3>
                <ol>
                    <li><strong>Ahorro Significativo:</strong> Nos comprometemos a ayudarte a ahorrar de manera significativa en
                        tus facturas de electricidad. No solo te proporcionamos información sobre el precio de la luz por horas,
                        sino que también te guiaremos para entender y optimizar tu factura de la luz.</li>
                    <li><strong>Educación Continua:</strong> A través de nuestro blog educativo, te proporcionamos recursos
                        valiosos para que aprendas técnicas prácticas, buenas prácticas y novedades relacionadas con la energía
                        eléctrica.</li>
                    <li><strong>Transparencia Absoluta:</strong> La transparencia es la piedra angular de nuestra relación
                        contigo. Te ofrecemos datos precisos y actualizados sobre el precio de la luz por horas y garantizamos la
                        seguridad de tus datos en todo momento.</li>
                </ol>

                <h2>La Experiencia <a href="/">luzporhoras.es</a></h2>
                <p>En <a href="/">luzporhoras.es</a>, no solo ofrecemos un servicio; brindamos una experiencia que transforma la
                    manera en que interactúas con la energía. Nuestra plataforma está diseñada para ser intuitiva, eficiente y,
                    sobre todo, beneficiosa para ti y para el planeta.</p>

                <h2>Únete a Nuestra Revolución Energética</h2>
                <p>Te invitamos a unirte a la revolución energética en <a href="/">luzporhoras.es</a>. Juntos, iluminaremos un
                    camino hacia un futuro más brillante, sostenible y económicamente sensato.</p>
                <p>¡Descubre el poder de la luz por horas en <a href="/">luzporhoras.es</a> hoy!</p>
            </div>
            <Footer />
        </div>
    );
}

export default AboutUs;