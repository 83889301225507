import React from "react";
import Header from "../../Header/components/Header";
import Footer from "../../Footer/components/Footer";
import "../styles/LegalFormat.css";
import { Helmet } from "react-helmet";

function PoliticPriv() {

    return (
        <div>
            <Helmet>
                <title>Política de Privacidad | LuzPorHoras.es</title>
            </Helmet>
            <Header />
            
            <div className="politics_container">
                <div className="politics_content_container">
                    <div className="politics_content">
                        <h1>Política de Privacidad</h1>

                        <p>Última actualización: 31 de enero de 2024</p>

                        <p>En luzporhoras.es, nos tomamos muy en serio la privacidad de nuestros usuarios. Esta política de privacidad
                            describe cómo recopilamos, usamos y protegemos la información personal que nos proporcionas a través de nuestro
                            sitio web.</p>

                        <h2>1. Información que Recopilamos</h2>
                        <p>Recopilamos la siguiente información personal cuando te registras en nuestro sitio o completas un formulario:</p>
                        <ol>
                            <li>Nombre</li>
                            <li>Correo Electrónico</li>
                        </ol>

                        <h2>2. Uso de la Información</h2>
                        <p>La información recopilada se utiliza para:</p>
                        <ul>
                            <li>Personalizar tu experiencia en nuestro sitio.</li>
                            <li>Enviar información, como boletines y notificaciones relevantes.</li>
                        </ul>

                        <h2>3. Protección de la Información</h2>
                        <p>Nos comprometemos a proteger la seguridad de tu información personal. Implementamos medidas de seguridad para
                            prevenir accesos no autorizados y garantizar la confidencialidad de la información que recopilamos.</p>

                        <h2>4. Compartir Información con Terceros</h2>
                        <p>No vendemos, intercambiamos ni transferimos tu información personal a terceros sin tu consentimiento, excepto
                            cuando sea necesario para brindar el servicio solicitado o cumplir con la ley.</p>

                        <h2>5. Enlaces a Otros Sitios</h2>
                        <p>Nuestro sitio web puede contener enlaces a sitios web de terceros. No nos hacemos responsables de las prácticas
                            de privacidad de estos sitios y te recomendamos leer sus políticas de privacidad.</p>

                        <h2>6. Cambios en la Política de Privacidad</h2>
                        <p>Nos reservamos el derecho de actualizar esta política de privacidad en cualquier momento. Te notificaremos sobre
                            cambios significativos mediante un aviso en nuestro sitio web o por correo electrónico.</p>

                        <h2>7. Contacto</h2>
                        <p>Si tienes preguntas sobre esta política de privacidad, por favor, <a href="/contacto">contáctanos</a> a tll.developers.apps@gmail.com.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}


export default PoliticPriv;