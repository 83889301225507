import React, { useEffect, useState } from "react";
import "../styles/TresPrecios.css";

function TresPrecios({ priceData }) {
    const [bestPrice, setBestPrice] = useState(0);
    const [worstPrice, setWorstPrice] = useState(0);
    const [averagePrice, setAveragePrice] = useState(0);

    useEffect(() => {
        let bestPriceTemp = null;
        let worstPriceTemp = null;
        let averagePriceTemp = null;
        priceData.forEach((data, index) => {

            if (index === 0) {
                bestPriceTemp = data;
                worstPriceTemp = data;
            } else {
                if (data.price > worstPriceTemp.price) {
                    worstPriceTemp = data;
                }
                if (data.price < bestPriceTemp.price) {
                    bestPriceTemp = data;
                }
            }
            averagePriceTemp = averagePriceTemp + data.price;
        });
        setBestPrice(bestPriceTemp);
        setWorstPrice(worstPriceTemp);
        setAveragePrice(averagePriceTemp/24);
    }, [priceData]);
    return (
        <div className="tres_precios_container">
            <div className="tres_precios">
                <h1>Precio más alto del día</h1>
                <h2>{String(worstPrice.hour).padStart(2, '0') + ":00h - " + String(worstPrice.hour + 1).padStart(2, '0') + ":00h "}</h2>
                <h3 style={{ "color": "#cf1717" }}>{worstPrice.price} €/MWh</h3>

            </div>
            <div className="tres_precios">
                <h1>Precio más bajo del día</h1>
                <h2>{String(bestPrice.hour).padStart(2, '0') + ":00h - " + String(bestPrice.hour + 1).padStart(2, '0') + ":00h "}</h2>
                <h3 style={{ "color": "#26cf17" }}>{bestPrice.price} €/MWh</h3>
            </div>
            <div className="tres_precios">
                <h1>Precio medio del día</h1>
                <h2>{Number(averagePrice).toFixed(2)}</h2>
                <h3>€/MWh</h3>
            </div>
        </div>
    )
}

export default TresPrecios