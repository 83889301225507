import React from "react";
import Header from "../../Header/components/Header";
import Footer from "../../Footer/components/Footer";
import FooterBlog from "../../Blog/components/FooterBlog";
import "../styles/Error404.css";
import { Helmet } from "react-helmet";

function Error404() {

    return (
        <div>
            <Helmet>
                <title>Página no encontrada | LuzPorHoras.es</title>
            </Helmet>
            <Header />
            <div className="error404_container">
                <div className="error404_70">
                    <div className="error404_70_1">
                        <h1>Ooops!...</h1>
                        <p>Parece que esta página está más apagada que el recibo de la luz. Parece que se esfumó en un destello. Mientras la encontramos, ¿por qué no explorar nuestras brillantes secciones?</p>
                        <a href="/">Accede a nuestra página principal</a>
                    </div>
                    <FooterBlog />
                </div>

            </div>


            <Footer />
        </div>
    );
}


export default Error404;