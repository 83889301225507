import React from "react";
import "../styles/AnuncioApp.css";
import logo_app from "../img/logo_app.png";
import play_store from "../img/play_store.png";

function AnuncioApp() {

    return (
        <div className="anuncio_app_container">
            <div className="anuncio_app_text_img">
                <div className="anuncio_app_text">
                    <h2><a href="/blog/ahorra-en-la-luz">¡Descarga la App!</a></h2>
                    <p> - Consulta los precios de la luz <strong>GRATIS</strong> en tiempo real desde tu móvil.</p>
                    <p> - ¡Con notificaciones que te avisan de las mejores horas!</p>
                </div>
                <div className="anuncio_app_img">
                    <img className="anuncio_app_logo" src={logo_app} alt="Logo App" />
                    <img className="anuncio_app_play_store" src={play_store} alt="Logo Play Store" />
                </div>
            </div>

            <button className="anuncio_app_button" onClick={()=>{window.open("https://play.google.com/store/apps/details?id=electricity.kwh.electricitykwh", "_blank")}}>¡Descarga Ahora!</button>
        </div>
    );
}

export default AnuncioApp;