import React from "react";
import Header from "../../Header/components/Header";
import Footer from "../../Footer/components/Footer";
import "../styles/Blog.css";
import icon from "../img/paper_clip.svg";
import totalArticles from "../../Entries";
import { Helmet } from "react-helmet";

function Blog() {

    const title = "Explora Nuestro Blog sobre Precios de la Luz | LuzPorHoras.es";
    const description = "Descubre información valiosa sobre la energía eléctrica y los precios de la luz en nuestro blog. Artículos sobre el KiloWatio/Hora, cómo entender tu factura de la luz, impuesto sobre la electricidad, diferentes facturas de luz, tarifas especiales, eficiencia energética y más. Encuentra soluciones, formas de ahorrar y las mejores tarifas de luz en LuzPorHoras.es.";
    const keywords = "blog precios de la luz, KiloWatio/Hora, factura de la luz, impuesto sobre la electricidad, factura de precio fijo, factura de luz por horas, factura de precio variable, factura con bono social, factura con energía renovable, tarifa PVPC, comercializadoras de referencia, peajes de acceso, mercados de futuro, gestión eficiente del consumo eléctrico, optimización energética, iluminación LED, formas de ahorrar en la factura de la luz, mejores tarifas de luz, LuzPorHoras.es.";


    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta name="author" content="Saúl Del Rosario Sosa" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content="https://s1.eestatic.com/2022/03/17/sociedad/consumo/657944297_222759406_1706x960.jpg" />
                <meta property="og:url" content="https://luzporhoras.es" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content="https://s1.eestatic.com/2022/03/17/sociedad/consumo/657944297_222759406_1706x960.jpg" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            <Header />
            <div className="blog_main_list">
                <div className="blog_list">
                    <h1>Puede que le interesen algunos de nuestros artículos ...</h1>
                    <p>¡Bienvenido a nuestra plataforma dedicada a la comprensión y optimización de tus consumos energéticos! Aquí encontrarás una amplia gama de artículos diseñados para iluminar cada aspecto de tu factura de luz y brindarte las herramientas necesarias para tomar decisiones informadas sobre tu consumo energético. Desde desgloses detallados sobre el kWh y su impacto en tu factura, hasta estrategias avanzadas para negociar con proveedores de energía, nuestro contenido está cuidadosamente elaborado para ayudarte a navegar por el complejo mundo de la energía eléctrica. ¿Estás listo para comenzar a optimizar tu consumo y ahorrar en tus facturas? ¡Explora nuestros artículos ahora mismo!</p>
                    <div className="blog_list_container">

                        {totalArticles.map((article, index) => {
                            return <a href={article.link}><img alt="Icono Leer Artículo" src={icon}></img>{article.nombre}</a>
                        })}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Blog;