import React, { useEffect } from "react";
import Chart from 'chart.js/auto';
import "../styles/Plot.css";

// your chart creation code here

function Plot({ priceData }) {

    useEffect(() => {
        // Dummy data for the chart
        const data = {
            labels: Array.from({ length: 24 }, (_, i) => String(i).padStart(2, '0') + ":00h"),
            datasets: [{
                label: '€/MWh',
                data: Array.from(priceData, (item) => item.price),
                borderWidth: 2,
                tension: 0.4,
                pointRadius: 4,
            }]
        };

        // Get the chart canvas element
        const ctx = document.getElementById('barChart').getContext('2d');

        const gradient = ctx.createLinearGradient(0, 0, 0, 500);
        gradient.addColorStop(0, 'rgba(255, 0, 0, 1)'); // Top color
        gradient.addColorStop(1, 'rgba(0, 255, 0, 1)'); // Bottom color

        // Apply the gradient to the dataset
        data.datasets[0].borderColor = gradient;
        data.datasets[0].backgroundColor = gradient;


        // Create the bar chart
        const myChart = new Chart(ctx, {
            type: 'line',
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: 'Precio PVPC Por Horas',
                        font: {
                            size: "18", // Add this line
                        },
                    },
                    legend: {
                        display: false, // Add this line
                    },
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        min: Number(priceData.map((item) => item.price).sort((a, b) => a - b)[0] - 20).toFixed(0),
                        max: Number(priceData.map((item) => item.price).sort((a, b) => b - a)[0] + 20).toFixed(0),
                        ticks: {
                            callback: function (value, index, values) {
                                // Don't show the label for the first and last data point
                                if (index === 0 || index === values.length - 1) {
                                    return '';
                                } else {
                                    return value;
                                }
                            }
                        }
                    }
                }
            }
        });

        // Cleanup function
        return () => {
            myChart.destroy();
        };
    }, []);



    return (
        <div className="plot_container" >
            <div style={{ width: "100%" }}>
                <canvas id="barChart" style={{minHeight: "400px"}}></canvas>
            </div>
            <h3>Fuente: Red Eléctrica de España <a href="https://www.ree.es/es">https://www.ree.es/es</a>. Precio <a href="/blog/tarifa-pvpc">PVPC</a> en €/MWh</h3>
        </div>
    );
}

export default Plot;