import React from "react";
import "../styles/Explains.css";

function ExplainAhorro() {
    return (
        <div className="explains_container">
            <div className="explains">
                <h1>Consejos para Optimizar tu Consumo de Electricidad</h1>

                <p>El precio por hora de la electricidad fluctúa a lo largo del día, con algunas horas siendo más caras que otras. Aprovechar esta información puede ayudarte a reducir tu factura de luz y contribuir al ahorro energético. A continuación, te ofrecemos consejos para cada hora del día:</p>

                <ul>
                    <li><strong>00:00h - 07:00h (Horas de Luz Más Baratas - Horas Valle):</strong>
                        <ul>
                            <li>Durante estas horas de la madrugada, el precio de la electricidad es más bajo. Aprovecha para cargar tus dispositivos electrónicos y electrodomésticos de mayor consumo, como lavadoras y lavavajillas.</li>
                            <li>Considera programar tareas de lavado y secado de ropa para este período, ya que utilizarán menos energía durante las horas más baratas.</li>
                        </ul>
                    </li>

                    <li><strong>08:00h - 13:00h (Horas Intermedias de Precio Electricidad - Horas Llano):</strong>
                        <ul>
                            <li>En estas horas de la mañana, los precios de la electricidad pueden estar en un punto intermedio. Evita el uso innecesario de electrodomésticos de alto consumo, como la plancha o el horno, durante este período.</li>
                            <li>Aprovecha para realizar tareas que no requieran mucho consumo de energía, como la limpieza del hogar o la preparación del desayuno.</li>
                        </ul>
                    </li>

                    <li><strong>14:00h - 18:00h (Horas de Luz Baratas - Horas Valle/Llano):</strong>
                        <ul>
                            <li>Estas horas de la tarde suelen tener precios más bajos de electricidad. Es el momento ideal para realizar tareas que requieran mayor consumo de energía, como cocinar, utilizar el horno, o poner la lavadora.</li>
                            <li>Aprovecha para realizar actividades que requieran el uso de dispositivos electrónicos, como trabajar en la computadora o ver televisión.</li>
                        </ul>
                    </li>

                    <li><strong>19:00h - 23:00h (Horas de Luz Más Caras - Horas Punta):</strong>
                        <ul>
                            <li>Durante estas horas de la noche, la electricidad tiende a ser más cara. Trata de reducir el consumo eléctrico evitando el uso de electrodomésticos innecesarios y apagando luces que no estés utilizando.</li>
                            <li>Considera adelantar algunas tareas para realizarlas durante las horas de menor demanda y precios más bajos.</li>
                        </ul>
                    </li>
                </ul>

                <p> Siguiendo estos consejos y adaptando tu consumo de electricidad según los precios por hora, podrás ahorrar hasta un 50% en tu factura de luz y contribuir al ahorro energético. Recuerda consultar el precio PVPC para aprovechar las horas más baratas y evitar las horas más caras.</p>

            </div>
        </div>
    )
}

export default ExplainAhorro