import React from "react";
import Header from "../../Header/components/Header";
import Footer from "../../Footer/components/Footer";
import "../styles/LegalFormat.css";
import { Helmet } from "react-helmet";

function AvisoLegal() {

    return (
        <div>
            <Helmet>
                <title>Aviso Legal | LuzPorHoras.es</title>
            </Helmet>
            
            <Header />
            <div className="politics_container">
                <div className="politics_content_container">
                    <div className="politics_content">
                        <h1>Aviso Legal</h1>

                        <p>Última actualización: 31 de enero de 2024</p>

                        <h2>1. Datos de la Empresa</h2>
                        <p>En cumplimiento con el deber de información recogido en el artículo 10 de la Ley 34/2002, de Servicios de la
                            Sociedad de la Información y del Comercio Electrónico, a continuación se detallan los datos de la empresa:</p>

                        <p>Razón Social: LuzPorHoras.es</p>
                        <p>Domicilio Social: Valencia, Mislata (46920)</p>
                        <p>Correo Electrónico: tll.developers.apps@gmail.com</p>

                        <h2>2. Propiedad Intelectual</h2>
                        <p>El contenido de este sitio web, incluyendo textos, imágenes, logotipos y cualquier otro material, está
                            protegido por derechos de propiedad intelectual e industrial propiedad de <strong>LuzPorHoras.es</strong> o de terceros
                            que hayan autorizado su uso.</p>

                        <h2>3. Uso del Sitio Web</h2>
                        <p>El acceso y uso de este sitio web atribuye la condición de usuario, aceptando las condiciones de uso
                            establecidas. El usuario se compromete a utilizar el sitio web y sus servicios y contenidos de manera
                            conforme a la ley, la moral, las buenas costumbres y el orden público.</p>

                        <h2>4. Enlaces a Terceros</h2>
                        <p>Este sitio web puede contener enlaces a sitios web de terceros. <strong>LuzPorHoras.es</strong> no se hace responsable
                            del contenido de esos sitios web ni de las prácticas de privacidad de esos sitios.</p>

                        <h2>5. Responsabilidad</h2>
                        <p><strong>LuzPorHoras.es</strong> no se hace responsable de los daños o perjuicios derivados del uso de este sitio web o
                            de sus contenidos. Además, <strong>LuzPorHoras.es</strong> no garantiza la disponibilidad, continuidad ni
                            infalibilidad del funcionamiento del sitio web.</p>

                        <h2>6. Legislación Aplicable</h2>
                        <p>Este aviso legal se rige en todos y cada uno de sus extremos por la legislación española. Para la resolución de
                            cualquier controversia relacionada con el sitio web o las actividades en él desarrolladas, será de aplicación
                            la legislación y jurisdicción españolas.</p>

                        <h2>7. Contacto</h2>
                        <p>Si tienes alguna pregunta sobre este aviso legal, por favor, contáctanos a tll.developers.apps@gmail.com.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}


export default AvisoLegal;