// CircularProgressBar.js
import React, { useState, useEffect } from 'react';
import '../styles/CircularProgressBar.css';
import red from "../img/RED.png";
import green from "../img/GREEN.png";
import yellow from "../img/YELLOW.png";


const CircularProgressBar = ({ radius, progressPercentage, priceActual, setColor, color }) => {

    const [progress, setProgress] = useState(0);
    const [textcolor, setTextcolor] = useState("rgb(0, 0, 0)");
    const [price, setPrice] = useState(0);

    const calcMax = (max) => {
        return max * 0.75;
    };

    function convertHSLToRGB(percentage) {
        const h = (1 - percentage / 75) * 120;
        let s = 1; // Saturation is at maximum
        let l = 0.4; // Lightness is at middle point
        let c = (1 - Math.abs(2 * l - 1)) * s,
            x = c * (1 - Math.abs((h / 60) % 2 - 1)),
            m = l - c / 2,
            r = 0,
            g = 0,
            b = 0;
        if (0 <= h && h < 60) {
            r = c; g = x; b = 0;
        } else if (60 <= h && h < 120) {
            r = x; g = c; b = 0;
        } else if (120 <= h && h < 180) {
            r = 0; g = c; b = x;
        } else if (180 <= h && h < 240) {
            r = 0; g = x; b = c;
        } else if (240 <= h && h < 300) {
            r = x; g = 0; b = c;
        } else if (300 <= h && h < 360) {
            r = c; g = 0; b = x;
        }
        r = Math.round((r + m) * 255);
        g = Math.round((g + m) * 255);
        b = Math.round((b + m) * 255);
        return (`rgb(${r}, ${g}, ${b})`);
        // return [r, g, b];
    }

    function getColor(percentage) {
        const hue = (1 - percentage / 75) * 120; // Map percentage to hue (green to red)
        return `hsl(${hue}, 100%, 50%)`;
    }
    const circumference = 2 * Math.PI * radius; // Circumference of the circle

    // Calculate the dash offset based on the progress

    useEffect(() => {
        let start = null;
        let intervalId = null;
    
        function step() {
            if (!start) start = Date.now();
            const elapsed = Date.now() - start;
    
            // Calculate the new progress based on the elapsed time
            const newProgress = Math.min(elapsed / 300 * 10, calcMax(progressPercentage));
            setProgress(newProgress);
            setPrice((newProgress / calcMax(progressPercentage)) * priceActual);
    
            setColor(getColor(newProgress));
            setTextcolor(convertHSLToRGB(newProgress));
    
            if (newProgress >= calcMax(progressPercentage)) {
                clearInterval(intervalId);
            }
        }
    
        // Start the animation
        intervalId = setInterval(step, 1000 / 60); // 60 FPS
    
        // Clean up on unmount
        return () => {
            clearInterval(intervalId);
        }
    }, [priceActual, progressPercentage, setColor]);

    const dashOffset = circumference * (1 - progress / 100);

    return (
        <div className="circular-progress-wrapper">
            <svg className="circular-progress" width="100%" height="100%">
                <circle
                    className="progress-bar"
                    cx="50%"
                    cy="50%"
                    r={radius}
                    strokeDasharray={circumference}
                    strokeDashoffset={dashOffset}
                    stroke={color}
                />

            </svg>
            <div className="progress-percentage">
                <span className="percentage" style={{ color: textcolor }}>{Number(price).toFixed(2)}</span>
                <span className="kwh" style={{ color: textcolor }}>€/MWh</span>
                {progress/0.75 < 34 ? <img className="icon" src={green} alt="Buena Hora" /> : progress/0.75 < 67 ? <img className="icon" src={yellow} alt="Ni Buena Ni Mala" /> : <img className="icon" src={red} alt="Mala Hora" />}
            </div>
        </div>
    );
};

export default CircularProgressBar;
