import React from "react";
import Header from "../../Header/components/Header";
import Footer from "../../Footer/components/Footer";
import "../styles/LegalFormat.css";
import { Helmet } from "react-helmet";

function PoliticCookies() {

    return (
        <div>
            <Helmet>
            <title>Política de Cookies | LuzPorHoras.es</title>
            </Helmet>
            <Header />
            <div className="politics_container">
                <div className="politics_content_container">
                    <div className="politics_content">
                        <h1>Política de Cookies</h1>

                        <p>Última actualización: 31 de enero de 2024</p>

                        <p>Esta Política de Cookies explica cómo luzporhoras.es utiliza cookies y tecnologías similares para
                            proporcionar, personalizar y mejorar tu experiencia en nuestro sitio.</p>

                        <h2>1. ¿Qué son las Cookies?</h2>
                        <p>Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo cuando visitas nuestro sitio
                            web. Estas cookies nos ayudan a reconocerte y recordar tu información para mejorar la experiencia del usuario.</p>

                        <h2>2. Tipos de Cookies Utilizadas</h2>
                        <p>A continuación, se describen los tipos de cookies que utilizamos:</p>
                        <ul>
                            <li><strong>Cookies Necesarias:</strong> Son esenciales para el funcionamiento del sitio y te permiten
                                navegar y utilizar sus funciones.</li>
                            <li><strong>Cookies de Rendimiento:</strong> Recopilan información sobre cómo los visitantes utilizan el
                                sitio, como las páginas que más se visitan.</li>
                            <li><strong>Cookies de Funcionalidad:</strong> Permiten al sitio recordar tus elecciones y personalizar tu
                                experiencia.</li>
                            <li><strong>Cookies de Publicidad:</strong> Se utilizan para ofrecerte anuncios relevantes basados en tus
                                intereses.</li>
                        </ul>

                        <h2>3. Consentimiento</h2>
                        <p>Al utilizar nuestro sitio, aceptas el uso de cookies de acuerdo con esta política. Puedes gestionar tus
                            preferencias de cookies a través de la configuración de tu navegador.</p>

                        <h2>4. Cookies de Terceros</h2>
                        <p>Este sitio web utiliza servicios de terceros, como Google Analytics, Google Ads y Google AdSense, que también
                            pueden utilizar cookies para proporcionar servicios y personalizar anuncios. Estos servicios pueden recopilar
                            información anónima sobre tu uso del sitio web.</p>

                        <h2>5. Desactivación de Cookies</h2>
                        <p>Puedes desactivar cookies en tu navegador, pero ten en cuenta que algunas funciones de nuestro sitio pueden no
                            funcionar correctamente sin ellas.</p>

                        <h2>6. Cambios en la Política de Cookies</h2>
                        <p>Nos reservamos el derecho de actualizar esta política de cookies en cualquier momento. Te notificaremos sobre
                            cambios significativos mediante un aviso en nuestro sitio web.</p>


                        <h2>6. Contacto</h2>
                        <p>Si tienes preguntas sobre nuestra política de cookies, por favor, contáctanos a tll.developers.apps@gmail.com.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}


export default PoliticCookies;